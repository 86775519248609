export const WORDS = [
  'champ',
  'union',
  'event',
  'meets',
  'phone',
  'nancy',
  'chick',
  'panda',
  'scott',
  'docks',
  'annes',
  'rooms',
  'sally',
  'lunch',
  'billy',
  'stair',
  'chris',
  'round',
  'truck',
  'barry',
  'steps',
  'cages',
  'lgbtq',
  'piano',
  'doors',
  'chair',
  'ukfcu',
  'jamie',
  'cater',
  'suite',
  'study',
  'mates',
  'party',
  'lyman',
  'easel',
  'guest',
  'planb',
  'squat',
  'visit',
  'table',
  'henry',
  'radio',
  'stand',
  'blank',
  'empty',
  'setup',
  'loads',
  'barry',
  'drink',
  'smith',
  'paper',
  'clean',
  'print',
  'guide',
  'staff',
  'music',
  'books',
  'covid',
  'plaza',
  'payne', 
]
